body {
  background-color: #022D41;
}
.card-group-container {
  max-width: 90%;
  margin: 0 auto;
}
.card-bg {
  background-color: #DAECF3;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #022D41;
  margin-top: 25px;
  margin-bottom: 25px;
}
.logo-container {
  flex: 1;
}
.logo {
  height: 50px;
}
.menu {
  flex: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button {
  margin-left: 10px;
}

